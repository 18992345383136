var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.organisations
        ? [
            _c("ck-select-input", {
              attrs: {
                value: _vm.organisation_id,
                id: "organisation_id",
                label: "Organisation*",
                hint: "Which organisation hosts this event?",
                options: _vm.organisations,
                error: _vm.errors.get("organisation_id")
              },
              on: {
                input: function($event) {
                  return _vm.onInput("organisation_id", $event)
                }
              }
            })
          ]
        : _vm._e(),
      _c("ck-text-input", {
        attrs: {
          value: _vm.title,
          id: "title",
          label: "Event title*",
          type: "text",
          error: _vm.errors.get("title")
        },
        on: {
          input: function($event) {
            return _vm.onInput("title", $event)
          }
        }
      }),
      _c(
        "ck-text-input",
        {
          attrs: {
            value: _vm.slug,
            id: "slug",
            label: "Unique slug",
            type: "text",
            error: _vm.errors.get("slug")
          },
          on: {
            input: function($event) {
              return _vm.onInput("slug", $event)
            }
          }
        },
        [
          _c(
            "gov-hint",
            { attrs: { slot: "hint", for: "slug" }, slot: "hint" },
            [
              _vm._v("\n      This will be used to access the event."),
              _c("br"),
              _vm._v(
                "\n      e.g. example.com/events/" + _vm._s(_vm.slug) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c("ck-date-picker", {
        attrs: {
          id: "start_date",
          value: _vm.start_date,
          max: _vm.end_date,
          required: true,
          error: _vm.errors.get("start_date"),
          label: "Start date*"
        },
        on: {
          input: function($event) {
            return _vm.onInput("start_date", $event)
          }
        }
      }),
      _c("ck-date-picker", {
        attrs: {
          id: "end_date",
          value: _vm.end_date,
          min: _vm.earliestEndDate,
          required: true,
          error: _vm.errors.get("end_date"),
          label: "End date*"
        },
        on: {
          input: function($event) {
            return _vm.onInput("end_date", $event)
          }
        }
      }),
      _c("ck-time-period-input", {
        attrs: {
          id: "event_times",
          opens_at: _vm.start_time,
          closes_at: _vm.end_time,
          opens_at_label: "Starting time*",
          closes_at_label: "Ending time*",
          error: _vm.errors.get(["start_time", "end_time"])
        },
        on: {
          "update:opens_at": function($event) {
            return _vm.onInput("start_time", $event)
          },
          "update:closes_at": function($event) {
            return _vm.onInput("end_time", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.intro,
          id: "intro",
          label: "Event summary*",
          type: "text",
          error: _vm.errors.get("intro")
        },
        on: {
          input: function($event) {
            return _vm.onInput("intro", $event)
          }
        }
      }),
      _c("ck-wysiwyg-input", {
        attrs: {
          value: _vm.description,
          id: "description",
          label: "Event description*",
          hint:
            "Describe the event with any details that attendees will need to decide on, find and attend your event. Use headers, bullets and formatting for the maximum effect.",
          error: _vm.errors.get("description"),
          large: "",
          maxlength: 10000
        },
        on: {
          input: function($event) {
            return _vm.onInput("description", $event)
          }
        }
      }),
      _c(
        "ck-radio-input",
        {
          attrs: {
            value: _vm.is_free,
            id: "is_free",
            label: "Is the event free?*",
            options: _vm.isFreeOptions,
            error: _vm.errors.get("is_free")
          },
          on: {
            input: function($event) {
              return _vm.onInput("is_free", $event)
            }
          }
        },
        [
          _c(
            "template",
            { slot: "hint" },
            [
              _c("gov-hint", { attrs: { for: "is_free" } }, [
                _vm._v(
                  "\n        Indicates whether your event is completely free, or if some elements\n        of the event must be paid for. Users can filter their searches based\n        on the answer you provide.\n      "
                )
              ]),
              _c("gov-hint", { attrs: { for: "is_free" } }, [
                _vm._v(
                  "\n        If the cost of your event varies, you may want to split these into\n        different event listings.\n      "
                )
              ])
            ],
            1
          )
        ],
        2
      ),
      _vm.is_free === false
        ? _c(
            "gov-inset-text",
            [
              _c("ck-text-input", {
                attrs: {
                  value: _vm.fees_text,
                  id: "fees_text",
                  label: "How much does it cost? (if applicable)",
                  hint:
                    'Please indicate the basic cost of the event. If there are multiple price points, please provide an indicative range (eg. "5-10 per session").',
                  type: "text",
                  error: _vm.errors.get("fees_text"),
                  maxlength: 75
                },
                on: {
                  input: function($event) {
                    return _vm.onInput("fees_text", $event)
                  }
                }
              }),
              _c("ck-text-input", {
                attrs: {
                  value: _vm.fees_url,
                  id: "fees_url",
                  label:
                    "Please provide a link to full pricing table (if applicable)",
                  type: "url",
                  error: _vm.errors.get("fees_url")
                },
                on: {
                  input: function($event) {
                    return _vm.onInput("fees_url", $event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("gov-section-break", { attrs: { size: "m", visible: "" } }),
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v("Organiser details")
      ]),
      _c("gov-body", [
        _vm._v(
          "\n    Please provide the details of the event organiser if there is one. If\n    there is not a specific organiser the contact details for the organisation\n    will be used\n  "
        )
      ]),
      _c("gov-section-break", { attrs: { size: "l" } }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.organiser_name,
          id: "organiser_name",
          label: "Organiser name",
          hint:
            "Provide the contact name (First name & Surname) for this event, or a generic entry if this isn’t applicable e.g. ‘Enquiries’, or ‘Helpdesk’.",
          type: "text",
          error: _vm.errors.get("organiser_name")
        },
        on: {
          input: function($event) {
            return _vm.onInput("organiser_name", $event)
          }
        }
      }),
      _c(
        "ck-text-input",
        {
          attrs: {
            value: _vm.organiser_phone,
            id: "organiser_phone",
            label: "Organiser phone number",
            type: "tel",
            error: _vm.errors.get("organiser_phone")
          },
          on: {
            input: function($event) {
              return _vm.onInput("organiser_phone", $event)
            }
          }
        },
        [
          _c(
            "template",
            { slot: "hint" },
            [
              _c("gov-hint", { attrs: { for: "organiser_phone" } }, [
                _vm._v(
                  "\n        Please provide a public facing phone number for attendees to contact\n        you on.\n      "
                )
              ]),
              _c("gov-hint", { attrs: { for: "organiser_phone" } }, [
                _vm._v(
                  "\n        Please enter your phone number without any spaces, prefixes or special\n        characters\n      "
                )
              ])
            ],
            1
          )
        ],
        2
      ),
      _c("ck-text-input", {
        attrs: {
          value: _vm.organiser_email,
          id: "organiser_email",
          label: "Public organiser email address",
          hint: "Please provide the contact email address for the event.",
          type: "email",
          error: _vm.errors.get("organiser_email")
        },
        on: {
          input: function($event) {
            return _vm.onInput("organiser_email", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.organiser_url,
          id: "organiser_url",
          label: "Public organiser web address",
          hint:
            "This must start with 'http://' or 'https://'. Your organisation’s website address will be used if the event doesn't have its own.",
          type: "url",
          error: _vm.errors.get("organiser_url")
        },
        on: {
          input: function($event) {
            return _vm.onInput("organiser_url", $event)
          }
        }
      }),
      _c("gov-section-break", { attrs: { size: "m", visible: "" } }),
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("How to book")]),
      _c("gov-body", [
        _vm._v(
          "\n    If your event requires booking, provide details of the booking process\n    including a link to a booking facility. All fields must be filled if this\n    is required.\n  "
        )
      ]),
      _c("ck-text-input", {
        attrs: {
          value: _vm.booking_title,
          id: "booking_title",
          label: "Title",
          hint: "Label for the booking section, e.g. 'How to book'.",
          type: "text",
          error: _vm.errors.get("booking_title")
        },
        on: {
          input: function($event) {
            return _vm.onInput("booking_title", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.booking_summary,
          id: "booking_summary",
          label: "Summary",
          hint:
            "Describe the booking process so attendees will know what to expect.",
          type: "text",
          error: _vm.errors.get("booking_summary")
        },
        on: {
          input: function($event) {
            return _vm.onInput("booking_summary", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.booking_url,
          id: "booking_url",
          label: "Booking web address",
          hint:
            "This must start with 'http://' or 'https://'. This should be the link to the booking facility for the event.",
          type: "url",
          error: _vm.errors.get("booking_url")
        },
        on: {
          input: function($event) {
            return _vm.onInput("booking_url", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.booking_cta,
          id: "booking_cta",
          label: "Button text",
          hint: "The text that will be used for the booking button.",
          type: "text",
          error: _vm.errors.get("booking_cta")
        },
        on: {
          input: function($event) {
            return _vm.onInput("booking_cta", $event)
          }
        }
      }),
      _c("gov-section-break", { attrs: { size: "m", visible: "" } }),
      _c(
        "ck-radio-input",
        {
          attrs: {
            value: _vm.is_virtual,
            id: "is_virtual",
            label: "Is the event virtual?*",
            options: _vm.isVirtualOptions,
            error: _vm.errors.get("is_virtual")
          },
          on: {
            input: function($event) {
              return _vm.onInput("is_virtual", $event)
            }
          }
        },
        [
          _c(
            "template",
            { slot: "hint" },
            [
              _c("gov-hint", { attrs: { for: "is_virtual" } }, [
                _vm._v(
                  "\n        Indicates whether your event occurs only online, or occurs at a\n        location\n      "
                )
              ])
            ],
            1
          )
        ],
        2
      ),
      _vm.is_virtual === false
        ? _c(
            "gov-inset-text",
            [
              _c("ck-location-input", {
                attrs: {
                  location_id: _vm.location_id,
                  error: _vm.errors.get("location_id")
                },
                on: {
                  "update:location_id": function($event) {
                    return _vm.onInput("location_id", $event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("gov-section-break", { attrs: { size: "m", visible: "" } }),
      _c("ck-image-input", {
        attrs: {
          id: "image",
          label: "Event image",
          "file-id": _vm.image_file_id
        },
        on: {
          input: function($event) {
            return _vm.onInput("image_file_id", $event.file_id)
          },
          "image-changed": function($event) {
            return _vm.$emit("image-changed", $event)
          }
        }
      }),
      _vm.auth.isGlobalAdmin
        ? _c("event-homepage-input", {
            attrs: {
              value: _vm.homepage,
              id: "homepage",
              label: "Show the Event on the homepage*",
              error: _vm.errors.get("homepage")
            },
            on: {
              input: function($event) {
                return _vm.onInput("homepage", $event)
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }